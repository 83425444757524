.carousel-item {
  height: 32rem;
}

.carousel-caption h1 {
  font-size: 3rem;
}

.carousel-caption p {
  font-size: 1.5rem;
}

.carousel-caption-top {
  top: 0;
  bottom: auto;
}

.carousel-item img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.carousel-item .carousel-caption {
  height: 50%;
  width: auto;
  object-fit: cover;
}

.carousel {
  margin-bottom: 4rem;
}

.tint {
  z-index: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
}
