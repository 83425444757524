@import "~bootstrap/dist/css/bootstrap.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

body {
  background-color: #f7fafc;
}

.table-striped thead {
  background-color: #455da8;
  color: #fff;
}

table.colspan-border {
  position: relative;
}

.colspan-border thead > tr:first-of-type th:not(:first-of-type)::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  border: 1px solid #6278be;
  margin-left: -0.4em;
  height: 100%;
}

.btn-primary {
  background-color: #6278be;
  border: none;
}

.btn-primary:hover:enabled {
  background-color: #53669e;
}

.btn-primary:disabled {
  background-color: #6278be;
  cursor: not-allowed;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):focus {
  background-color: #3b4970;
}

/* modal */
.modal-header {
  background-color: #6278be;
  color: #fff;
}
.modal-header .close {
  color: #fff;
}

.modal-content {
  border-radius: 0.4rem;
}

.bg-info-panel {
  background-color: #7691e2;
}

td a {
  margin-right: 5px;
}

@media only screen and (min-width: 1296px) {
  .navbar-dark .navbar-nav .nav-link {
    font-size: 1.25rem;
  }
}