#gmap_canvas {
  border: 0;
}

.mapouter {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.mapouter iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
