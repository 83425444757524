.navbar.navbar-dark {
  background-color: #3D5499;
}

.nav-link {
  cursor: pointer;
}

@media (max-width: 991.98px) {
  a.dropdown-item {
    text-align: center;
  }
}